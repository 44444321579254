import { MetadataMappingService} from '@/modules/metadata-mapping/metadata-mapping-service';
import Errors from '@/shared/error/errors';

// action types
export const GET_ALL_DOCUMENTS_TYPES = 'getAllDocumentsTypes';
export const GET_DOCUMENTS_TYPE_BY_ID = 'getDocumentTypeById';
export const GET_APPLICATIONS_TYPES = 'getApplicationsTypes';
export const GET_APPLICATIONS_METADATA = 'getApplicationMetadata';
export const GET_APPLICATIONS_METADATA_STATIC = 'getApplicationMetadataStatic';
export const UPDATE_MAPPING = 'updateMapping';
export const DELETE_MAPPED_METADATA = 'removeMappedMetadata';
export const UPDATE_MAPPED_METADATA = 'updateMappedMetadata';

// mutation types
export const SET_DOCUMENT_TYPES = 'setDocumentTypes';
export const SET_DOCUMENT_METADATA = 'setDocumentMetadata';
export const SET_APPLICATIONS_TYPES = 'setApplicationsTypes';
export const SET_APPLICATIONS_METADATA = 'setApplicationsMetadata';
export const SET_ALL_APPLICATIONS_METADATA = 'setAllApplicationsMetadata';

const state = {
    documentTypes: [],
    documentMetadata: [],
    applicationsTypes: [],
    applicationsMetadata: [],
    allApplicationsMetadata: [],
};

const getters = {
    documentTypes: (state) => {
        return state.documentTypes.map((doc) => ({
            text: doc.name,
            value: doc.id.toString(),
            docObj: doc
        }))
    },
    documentMetadata: (state) => {
        return state.documentMetadata.map((doc) => ({
            text: doc.mandatory ? `(M) ${doc.name} - ${doc.label}`: `${doc.name} - ${doc.label}`,
            value: doc.id.toString(),
            docObj: doc
        }))
    },
    applicationsTypes: (state) => {
        return state.applicationsTypes.map((app) => ({
            value: app.id,
            text: app.name,
            docObj: app
        }))
    },
    applicationsMetadata: (state) => {
        return state.applicationsMetadata.map((app) => ({
            value: app.id,
            text: app.name,
        }))
    },
    allApplicationsMetadata: (state) => state.allApplicationsMetadata,
};

const mutations = {
    [SET_DOCUMENT_TYPES](state, documentTypes) {
        state.documentTypes = documentTypes;
    },
    [SET_DOCUMENT_METADATA](state, documentMetadata) {
        state.documentMetadata = documentMetadata;
    },
    [SET_APPLICATIONS_TYPES](state, applicationsTypes) {
        state.applicationsTypes = applicationsTypes;
    },
    [SET_APPLICATIONS_METADATA](state, data) {
        state.applicationsMetadata = data;
    },
    [SET_ALL_APPLICATIONS_METADATA](state, data) {
        state.allApplicationsMetadata = data;
    },
};

const actions = {
    [GET_ALL_DOCUMENTS_TYPES](context) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'metadataMapping/getAllDocumentsTypes', { root: true });
            context.dispatch('setMenu', 'archive', { root: true });
            MetadataMappingService.getAllDocumentsTypes()
                .then((data) => {
                    context.commit(SET_DOCUMENT_TYPES, data.data);
                    resolve(data);
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'metadataMapping/getAllDocumentsTypes', { root: true });
                    context.dispatch('setMenu', 'default', { root: true });
                });
        });
    },
    [GET_DOCUMENTS_TYPE_BY_ID](context,id) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'metadataMapping/getDocumentTypeById', { root: true });
            context.dispatch('setMenu', 'archive', { root: true });
            MetadataMappingService.getDocumentTypeById(id)
            .then((data) => {
                context.commit(SET_DOCUMENT_METADATA, data.data.metadataDefinitions);
                resolve(data);
            })
            .catch(( error ) => {
                Errors.handle(error);
                reject();
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'metadataMapping/getDocumentTypeById', { root: true });
                context.dispatch('setMenu', 'default', { root: true });
            });
        });
    },
    [GET_APPLICATIONS_TYPES](context) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'metadataMapping/getApplicationsTypes', { root: true });
            MetadataMappingService.getApplicationsTypes()
            .then((data) => {
                context.commit(SET_APPLICATIONS_TYPES, data.data);
                resolve(data);
            })
            .catch(( error ) => {
                Errors.handle(error);
                reject();
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'metadataMapping/getApplicationsTypes', { root: true });
            });
        });
    },
    [GET_APPLICATIONS_METADATA_STATIC](context) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'metadataMapping/getApplicationMetadataStatic', { root: true });
            MetadataMappingService.getApplicationMetadataStatic()
            .then((data) => {
                context.commit(SET_APPLICATIONS_METADATA, data);
                resolve(data);
            })
            .catch(( error ) => {
                Errors.handle(error);
                reject();
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'metadataMapping/getApplicationMetadataStatic', { root: true });
            });
        });
    },
    [GET_APPLICATIONS_METADATA](context, id) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'metadataMapping/getApplicationMetadata', { root: true });
            MetadataMappingService.getApplicationMetadata({ params: { application_type: id , limit: 100}})
            .then((data) => {
                context.commit(SET_ALL_APPLICATIONS_METADATA, data);
                resolve(data);
            })
            .catch(( error ) => {
                Errors.handle(error);
                reject();
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'metadataMapping/getApplicationMetadata', { root: true });
            });
        });
    },
    [UPDATE_MAPPING](context, params) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'metadataMapping/updateMapping', { root: true });
            MetadataMappingService.updateMapping(params)
            .then((data) => {
                resolve(data);
            })
            .catch(( error ) => {
                Errors.handle(error);
                reject();
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'metadataMapping/updateMapping', { root: true });
            });
        });
    },
    [DELETE_MAPPED_METADATA](context, id) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'metadataMapping/removeMappedMetadata', { root: true });
            MetadataMappingService.removeMappedMetadata(id)
            .then(() => {
                resolve();
            })
            .catch(( error ) => {
                Errors.handle(error);
                reject();
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'metadataMapping/removeMappedMetadata', { root: true });
            });
        });
    },
    [UPDATE_MAPPED_METADATA](context, {id, payload}) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'metadataMapping/updateMappedMetadata', { root: true });
            MetadataMappingService.updateMappedMetadata({id, payload})
            .then(() => {
                resolve();
            })
            .catch(( error ) => {
                Errors.handle(error);
                reject();
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'metadataMapping/updateMappedMetadata', { root: true });
            });
        });
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
